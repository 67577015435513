<template>
  <div>
    <!-- Time filters  -->
    <v-card class="mb-4 px-4 pb-4">
      <TimeFilters
        @timeFilterChanged="changeTimeFilters"
      />
    </v-card>

    <!-- Map based filters and maps  -->
    <v-card class="mb-4 mt-4">
      <v-tabs
        v-model="tab"
        grow
      >
        <v-tab>Age, Gender, Tariff Filters</v-tab>
        <v-tab>Country of origin Filter</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div class="mx-4 my-4 py-4">
            <AgeGenderTariffFilters
              class="mb-3"
              @ageGenderTariffFilterChanged="ageGenderCountryFilter"
            />
            <AgeGenderTariffMap
              :age-groups="ageGroups"
              :genders="genders"
              :tariffs="tariffs"
              :selected-time-range="selectedTimeRange"
              :selected-from="selectedFrom"
              :selected-until="selectedUntil"
            />
          </div>
        </v-tab-item>

        <v-tab-item>
          <div class="mx-4 my-4 py-4">
            <CountryFilters
              class="mb-3"
              :selected-time-range="selectedTimeRange"
              :selected-from="selectedFrom"
              :selected-until="selectedUntil"
              @countrySelected="countryFilter"
            />
            <CountryMap
              :countries="countries"
              :selected-time-range="selectedTimeRange"
              :selected-from="selectedFrom"
              :selected-until="selectedUntil"
            />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import CountryMap from '../components/teddy/CountryMap.vue'
import AgeGenderTariffMap from '../components/teddy/AgeGenderTariffMap.vue'

import TimeFilters from '../components/heatmaps-shared/TimeFilters.vue'
import CountryFilters from '../components/teddy/CountryFilters.vue'
import AgeGenderTariffFilters from '../components/teddy/AgeGenderTariffFilters.vue'

export default {
  components: {
    CountryMap,
    AgeGenderTariffMap,

    TimeFilters,
    CountryFilters,
    AgeGenderTariffFilters,
  },

  data() {
    return {
      tab: null,

      ageGroups: ['AG_ALL'],
      genders: ['ALL'],
      tariffs: ['ALL'],

      countries: [],

      selectedTimeRange: '',
      selectedFrom: '',
      selectedUntil: '',
    }
  },

  methods: {
    ageGenderCountryFilter(age, gender, tariff) {
      this.ageGroups = age
      this.genders = gender
      this.tariffs = tariff
    },

    countryFilter(selectedCountries) {
      this.countries = selectedCountries
    },

    changeTimeFilters(selectedFrom, selectedUntil, selectedTimeRange) {
      this.selectedFrom = this.$moment(selectedFrom).format()
      this.selectedUntil = this.$moment(selectedUntil).format()
      this.selectedTimeRange = selectedTimeRange
    },
  },
}
</script>
