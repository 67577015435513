<template>
  <v-row>
    <v-col
      md="4"
      sm="6"
      cols="12"
    >
      <div class="pl-1 mb-1">
        Time rate
      </div>
      <v-select
        v-model="selectedTimeRange"
        :items="timeRateOptions"
        solo
        hide-details
      />
    </v-col>

    <v-col
      md="4"
      sm="6"
      cols="12"
    >
      <div class="pl-1 mb-1">
        From
      </div>
      <div>
        <v-select
          v-model="selectedFrom"
          :items="dts.slice(1)"
          item-disabled="fromDisabled"
          solo
          hide-details
        />
      </div>
    </v-col>
    <v-col
      md="4"
      sm="6"
      cols="12"
    >
      <div class="pl-1 mb-1">
        Until
      </div>
      <div>
        <v-select
          v-model="selectedUntil"
          :items="dts"
          item-disabled="untilDisabled"
          solo
          hide-details
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>

export default {
  data() {
    return {
      timeOptions: [],
      timeRateOptions: [
        { text: 'Hourly', value: 'HOURLY' },
        { text: 'Quarterly', value: 'QUARTERLY' },
      ],

      selectedTimeRange: 'QUARTERLY',
      selectedFrom: '',
      selectedUntil: '',
    }
  },

  computed: {
    timeRange() {
      switch (this.selectedTimeRange) {
        case 'HOURLY':
          return 60

        case 'QUARTERLY':
        default:
          return 15
      }
    },

    until() {
      return this.$moment()
    },

    from() {
      return this
        .$moment(this.until)
        .subtract(1, 'days')
        .startOf('day')
    },

    diff() {
      return Math.ceil(this.until.diff(this.from, 'minutes', true) / this.timeRange)
    },

    dts() {
      const dateTimes = []

      for (let index = 0; index < this.diff; index += 1) {
        const dt = this.$moment(this.from).add((index * this.timeRange), 'minutes')
        dateTimes.push({
          text: dt.format('DD/MM/YYYY HH:mm'),
          value: dt.format('YYYY-MM-DDTHH:mm:ssZ'),
          fromDisabled: index === (this.diff - 1),
          untilDisabled: false,
        })
      }

      return dateTimes.reverse()
    },

    placeholderDts() {
      const [{ value: until }, { value: from }] = Array.from(this.dts).slice(0, 2)

      return {
        from,
        until,
      }
    },
  },

  watch: {
    selectedTimeRange() {
      this.timeFilterChange()
    },

    selectedFrom: {
      handler(newVal, oldVal) {
        this.dts.forEach(time =>
          Object.assign(time, { untilDisabled: time.value <= String(this.selectedFrom) }))

        if (newVal !== oldVal) {
          this.timeFilterChange()
        }
      },
      deep: true,
    },

    selectedUntil: {
      handler(newVal, oldVal) {
        this.dts.forEach(time =>
          Object.assign(time, { fromDisabled: time.value >= String(this.selectedUntil) }))

        if (newVal !== oldVal) {
          this.timeFilterChange()
        }
      },
      deep: true,
    },

    dts() {
      this.initDateTime()
    },
  },

  mounted() {
    this.initDateTime()
  },

  methods: {
    // Emitting time filter change to parent component
    timeFilterChange() {
      this.$emit('timeFilterChanged', this.selectedFrom, this.selectedUntil, this.selectedTimeRange)
    },

    initDateTime() {
      this.selectedFrom = this.placeholderDts.from
      this.selectedUntil = this.placeholderDts.until
    },
  },
}
</script>

<style>
.title-styling {
  font-size: 1.25em;
}
</style>
