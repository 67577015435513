<template>
  <l-map
    ref="map"
    class="map-styling"
    style="height: 800px; width: 100%"
    :zoom="zoom"
    :center="center"
    :layers="layers.layer"
    :options="leafletOptions"
    @update:zoom="zoomUpdated"
    @update:center="centerUpdated"
    @update:bounds="boundsUpdated"
  >
    <!-- Meer movement data - direction vectors with color that determines speed of movement -->
    <div v-if="showMovementLayer">
      <div
        v-for="movement in movementData"
        :key="movement.id"
      >
        <l-polyline-decorator
          :paths="[[movement.startingPoint.lat, movement.startingPoint.lon], [movement.destinationPoint.lat, movement.destinationPoint.lon]]"
          :patterns="arrowheadColor(movement.speed, true)"
        />
        <l-polyline
          :stroke="zoom > 14"
          :weight="8"
          :lat-lngs="[[movement.startingPoint.lat, movement.startingPoint.lon], [movement.destinationPoint.lat, movement.destinationPoint.lon]]"
          color="#000"
        />
        <l-polyline-decorator
          :paths="[[movement.startingPoint.lat, movement.startingPoint.lon], [movement.destinationPoint.lat, movement.destinationPoint.lon]]"
          :patterns="arrowheadColor(movement.speed)"
        />
        <l-polyline
          :stroke="zoom > 14"
          :weight="3"
          :lat-lngs="[[movement.startingPoint.lat, movement.startingPoint.lon], [movement.destinationPoint.lat, movement.destinationPoint.lon]]"
          :color="arrowColor(movement.speed)"
        />
      </div>
    </div>

    <l-tile-layer
      :url="$tileService.url"
      :attribution="$tileService.attribution"
    ></l-tile-layer>
  </l-map>
</template>

<script>
import { Symbol } from 'leaflet'
import { LMap, LTileLayer, LPolyline } from 'vue2-leaflet'

import HeatmapOverlay from 'leaflet-heatmap'
import Vue2LeafletPolylineDecorator from 'vue2-leaflet-polylinedecorator'

export default {
  components: {
    LMap,
    LTileLayer,
    LPolyline,

    'l-polyline-decorator': Vue2LeafletPolylineDecorator,
  },

  props: {
    // Teddy, Meer
    mapData: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },

    // Meer
    movementData: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },

    showMovementLayer: {
      typeof: Boolean,
      required: false,
      dafault: true,
    },

    showStayLayer: {
      typeof: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      zoom: 13,
      center: [45.34481892275193, 14.44775250818104],
      bounds: null,
      layers: [
        {
          name: 'Weather Data',
          visible: true,
          format: 'image/png',
          layer: 'nexrad-n0r-900913',
          transparent: true,
          attribution: 'Weather data © 2012 IEM Nexrad',
        },
      ],

      leafletOptions: {
        minZoom: 13,
        maxZoom: 16,
        /* eslint-disable-next-line no-undef */
        maxBounds: L.latLngBounds(L.latLng(45.451196, 14.287033), L.latLng(45.267372, 14.581686)),
      },

      heatmapLayer: undefined,

      // Meer heatmap layer arrow decoration
      patterns: [
        {
          offset: '100%',
          symbol: Symbol.arrowHead({ polygon: false, pixelSize: 10, pathOptions: { color: '#ffffff' } }),
          repeat: false,
        },
      ],

      mapBounds: undefined,
    }
  },

  watch: {
    mapData: {
      handler(newVal) {
        this.updateHeatmapLayer(newVal)
      },
      deep: true,
    },

    showStayLayer: {
      handler() {
        if (!this.showStayLayer) {
          if (this.mapData.length > 0) {
            this.heatmapLayer.setData({
              max: 0,
              data: [],
            })
          }
        } else {
          this.updateHeatmapLayer()
        }
      },
    },
  },

  async mounted() {
    await this.$nextTick()

    // Heatmap layer - used by Zanty and Meer
    // Meer - used to show average user stay time in a certain geohash cell

    this.heatmapLayer = new HeatmapOverlay({
      radius: 0.003,
      maxOpacity: 0.4,
      scaleRadius: true,
      useLocalExtrema: false,
      latField: 'lat',
      lngField: 'lng',
      valueField: 'count',
    })

    this.$refs.map.mapObject.addLayer(this.heatmapLayer)

    if (this.mapData.length > 0) this.updateHeatmapLayer()
  },

  methods: {
    updateHeatmapLayer(mapData) {
      /* eslint-disable-next-line no-param-reassign */
      mapData = mapData || this.mapData

      if (this.heatmapLayer) {
        this.heatmapLayer.setData({
          max: Math.max(...mapData.map(({ count }) => count)),
          data: mapData,
        })
      }
    },

    zoomUpdated(zoom) {
      this.zoom = zoom
    },

    centerUpdated(center) {
      this.center = center
    },

    boundsUpdated(bounds) {
      this.bounds = bounds
    },

    arrowColor(speed) {
      let color = '#ffffff'

      if (speed >= 0 && speed <= 10) color = '#00abff'
      else if (speed > 10 && speed <= 50) color = '#4eff00'
      else if (speed > 50 && speed <= 75) color = '#ffe12c'
      else if (speed > 75 && speed <= 100) color = '#ffa300'
      else if (speed > 100) color = '#ff0000'

      return color
    },

    arrowheadColor(speed, isOutline) {
      const isOutlineReal = isOutline || false
      const color = !isOutlineReal ? this.arrowColor(speed) : '#000'

      return [
        {
          offset: '100%',
          symbol: Symbol.arrowHead({
            polygon: false,
            pixelSize: 10,
            pathOptions: { color, weight: isOutlineReal ? 8 : 3 },
          }),
          repeat: false,
        },
      ]
    },
  },
}
</script>
