<template>
  <v-row v-if="countryData">
    <v-col cols="12">
      <div class="pl-1">
        Country of origin
      </div>

      <v-card>
        <v-progress-linear
          bottom
          absolute
          indeterminate
          :active="$apollo.queries.heatmapAvailableCountries.loading"
        ></v-progress-linear>

        <v-autocomplete
          v-model="selectedCountries"
          label="All"
          style="z-index: 0;"
          :search-input.sync="countrySearch"
          :items="heatmapCountriesDropdown"
          :disabled="$apollo.queries.heatmapAvailableCountries.loading"
          clearable
          multiple
          deletable-chips
          small-chips
          solo
          hide-details
          @change="countrySelected"
        >
          <template #no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results.
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import gql from 'graphql-tag'
import { isNil as _isNil } from 'lodash'

import allCountriesData from 'country-data'

export default {
  props: {
    selectedTimeRange: {
      type: String,
      required: false,
      default: 'QUARTERLY',
    },

    selectedFrom: {
      type: String,
      required: false,
      default: '',
    },

    selectedUntil: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      selectedCountries: [],

      countryData: undefined,

      countrySearch: '',
    }
  },

  apollo: {
    heatmapAvailableCountries: {
      query: gql`
        query ($timeRange: AnalyticsTimeRange!, $from: DateTimeTz, $until: DateTimeTz) {
          heatmapAvailableCountries(timeRange: $timeRange, from: $from, until: $until, onlyNonZero: true)
        }
      `,
      variables() {
        return {
          timeRange: this.selectedTimeRange,
          from: this.selectedFrom,
          until: this.selectedUntil,
        }
      },
      skip() {
        return !this.selectedTimeRange || !this.selectedFrom || !this.selectedUntil
      },
    },
  },

  computed: {
    heatmapCountriesDropdown() {
      const array = []

      if (!_isNil(this.heatmapAvailableCountries)) {
        this.heatmapAvailableCountries.forEach(country => {
          if (this.countryData[country.toUpperCase()]) {
            array.push({
              value: country,
              text: this.countryData[country.toUpperCase()].name,
            })
          }
        })
      }

      return array
    },
  },

  watch: {
    selectedCountries: {
      handler() {
        this.$emit('countrySelected', this.selectedCountries)
      },
      deep: true,
    },
  },

  mounted() {
    this.countryData = allCountriesData.countries
  },

  methods: {
    countrySelected() {
      this.countrySearch = ''
    },
  },
}
</script>

<style>
.v-menu__content {
  z-index: 1 !important;
}
</style>
