<template>
  <v-row>
    <v-col
      lg="2"
      md="3"
      cols="6"
    >
      <div class="pl-1 mb-1">
        Gender
      </div>
      <v-select
        v-model="selectedGender"
        :items="genderGroups"
        solo
        hide-details
      />
    </v-col>
    <v-col
      lg="2"
      md="3"
      cols="6"
    >
      <div class="pl-1 mb-1">
        Tariff
      </div>
      <v-select
        v-model="selectedTariff"
        :items="tariffGroups"
        solo
        hide-details
      />
    </v-col>
    <v-col
      sm="9"
      md="4"
      lg="6"
      cols="12"
    >
      <div class="pl-1 mb-1">
        Age
      </div>
      <v-select
        v-model="selectedAge"
        :items="ageGroups"
        chips
        multiple
        solo
        hide-details="auto"
        :error-messages="ageErrorMessages"
      >
        <template #selection="{ item }">
          <div>
            <div v-if="item.value === 'AG_ALL'"></div>
            <div v-else>
              <v-chip color="#B7BF38">
                {{ item.text }}
              </v-chip>
            </div>
          </div>
        </template>
      </v-select>
    </v-col>
    <v-col
      cols="auto"
      class="d-flex"
    >
      <v-checkbox
        v-model="selectAllAge"
        label="All age"
        class="align-self-center"
        hide-details
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    ageErrorMessages: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
  data() {
    return {
      ageGroups: [
        { text: '18-25', value: 'AG_18_25' },
        { text: '25-35', value: 'AG_25_35' },
        { text: '35-45', value: 'AG_35_45' },
        { text: '45-55', value: 'AG_45_55' },
        { text: '55-65', value: 'AG_55_65' },
        { text: '65+', value: 'AG_65_AND_UP' },
      ],

      genderGroups: [
        { text: 'All', value: 'ALL' },
        { text: 'Female', value: 'FEMALE' },
        { text: 'Male', value: 'MALE' },
      ],

      tariffGroups: [
        { text: 'All', value: 'ALL' },
        { text: 'Business', value: 'BUSINESS' },
        { text: 'Private', value: 'PRIVATE' },
      ],

      selectedAge: ['AG_18_25', 'AG_25_35', 'AG_35_45', 'AG_45_55', 'AG_55_65', 'AG_65_AND_UP'],
      selectAllAge: true,
      selectedGender: 'ALL',
      selectedTariff: 'ALL',
    }
  },

  watch: {
    selectedAge: {
      handler() {
        this.selectAllAge = this.selectedAge.length === this.ageGroups.length

        this.filterChange()
      },
      deep: true,
    },

    selectAllAge: {
      handler() {
        if (this.selectAllAge) {
          this.selectedAge = ['AG_18_25', 'AG_25_35', 'AG_35_45', 'AG_45_55', 'AG_55_65', 'AG_65_AND_UP']
        } else if (this.selectedAge.length === this.ageGroups.length) {
          this.selectedAge = []
        }
      },
    },

    selectedGender: {
      handler() {
        this.filterChange()
      },
      deep: true,
    },

    selectedTariff: {
      handler() {
        this.filterChange()
      },
      deep: true,
    },
  },

  methods: {
    filterChange() {
      const selectedGenderArr = [this.selectedGender]
      const selectedTariffArr = [this.selectedTariff]

      this.$emit('ageGenderTariffFilterChanged', this.selectedAge, selectedGenderArr, selectedTariffArr)
    },
  },
}
</script>
