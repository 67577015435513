<template>
  <div>
    <v-overlay :value="$apollo.queries.heatmapByCohortData.loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>

    <LeafletMap
      :map-data="mapData"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'

import LeafletMap from '../heatmaps-shared/LeafletMap.vue'

export default {
  components: {
    LeafletMap,
  },

  props: {
    ageGroups: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },

    genders: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },

    tariffs: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },

    selectedTimeRange: {
      typeof: String,
      default: '',
    },

    selectedFrom: {
      typeof: String,
      default: '',
    },

    selectedUntil: {
      typeof: String,
      default: '',
    },
  },

  apollo: {
    heatmapByCohortData: {
      query: gql`
        query (
          $ageGroups: [AgeGroup!]
          $genders: [Gender!]
          $tariffs: [Tariff!]
          $timeRange: AnalyticsTimeRange!
          $from: DateTimeTz
          $until: DateTimeTz
        ) {
          heatmapByCohortData(
            ageGroups: $ageGroups
            genders: $genders
            tariffs: $tariffs
            timeRange: $timeRange
            from: $from
            until: $until
            onlyNonZero: true
          ) {
            cells {
              cellId
              pin {
                lat
                lng
              }
              cohorts {
                ageGroup
                gender
                tariff
                count
              }
            }
          }
        }
      `,
      variables() {
        return {
          ageGroups: this.ageGroups,
          genders: this.genders,
          tariffs: this.tariffs,
          timeRange: this.selectedTimeRange,
          from: this.selectedFrom,
          until: this.selectedUntil,
        }
      },
      fetchPolicy: 'network-only',
    },
  },

  computed: {
    mapData() {
      const array = []

      if (this.heatmapByCohortData) {
        this.heatmapByCohortData.cells.forEach(cell => {
          array.push({
            lat: cell.pin.lat,
            lng: cell.pin.lng,
            count: cell.cohorts[0].count,
          })
        })
      }

      return array
    },
  },
}
</script>
