<template>
  <div>
    <v-overlay :value="$apollo.queries.heatmapByCountryData.loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>

    <LeafletMap
      :map-data="mapData"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'

import LeafletMap from '../heatmaps-shared/LeafletMap.vue'

export default {
  components: {
    LeafletMap,
  },

  props: {
    countries: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },

    selectedTimeRange: {
      type: String,
      required: false,
      default: '',
    },

    selectedFrom: {
      type: String,
      required: false,
      default: '',
    },

    selectedUntil: {
      type: String,
      required: false,
      default: '',
    },
  },

  apollo: {
    heatmapByCountryData: {
      query: gql`
        query ($countries: [String!], $timeRange: AnalyticsTimeRange!, $from: DateTimeTz, $until: DateTimeTz) {
          heatmapByCountryData(
                countries: $countries,
                timeRange: $timeRange,
                from: $from,
                until: $until,
                onlyNonZero: true
          ) {
            total
            cells {
              cellId
              total
              pin {
                lat
                lng
              }
              countries {
                countryIso
                count
              }
            }
          }
        }
      `,
      variables() {
        return {
          countries: this.countries,
          timeRange: this.selectedTimeRange,
          from: this.selectedFrom,
          until: this.selectedUntil,
        }
      },
      fetchPolicy: 'network-only',
    },
  },

  computed: {
    mapData() {
      const array = []

      if (this.heatmapByCountryData) {
        this.heatmapByCountryData.cells.forEach(cell => {
          array.push({
            lat: cell.pin.lat,
            lng: cell.pin.lng,
            count: cell.total,
          })
        })
      }

      return array
    },
  },
}
</script>
